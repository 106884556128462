<template>
  <div>
    <b-card no-body class="mb-1">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                v-model="perPage"
                :options="perPageOptions"
                @input="setPerPageSelected"
                :clearable="false"
                :searchable="false"
              />
            </div>
          </div>
          
          <div class="col-md-5">
            <div class="form-group mb-md-0 mb-1">
              <input
                type="search"
                class="form-control d-inline-block"
                placeholder="Título"
                v-model="filters.title"
              />
            </div>
          </div>

          <div class="col-md-5">
            <div class="form-group">
              <v-select
                label="title"
                item-text="title"
                item-value="code"
                v-model="filters.course_sale"
                placeholder="Curso Venda"
                :options="optionsCourseSales"
                @search="fetchCourseSale"
                :clearable="true"
                class="text-elipse-300"
              >
                <span slot="no-options" class="text-left">
                  Digite o título do curso venda
                </span>
              </v-select>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                v-model="filters.course_type"
                :options="optionsCourseTypes"
                :clearable="true"
                :searchable="false"
                placeholder="Tipo"
              />
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Situação"
                v-model="filters.status"
                :options="[{ code: 1, title: 'Ativo' }, { code: 0, title: 'Inativo' }]"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Ficha técnica"
                v-model="filters.datasheet"
                :options="optionsDatasheets"
                @search="fetchDatasheets"
                :clearable="true"
                class="text-elipse-300"
              />
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group mb-md-0 mb-1">
              <v-select
                label="title"
                required
                placeholder="Escolaridade"
                v-model="filters.scholarity"
                :options="optionsScholarities"
                :clearable="true"
                :searchable="false"
              />
            </div>
          </div>
          
          <div class="col-md-1">
            <button
              @click="search"
              style="padding: 14px !important"
              type="button"
              class="btn btn-secondary btn-block"
            >
              <i v-if="!btnSearch" class="bi bi-search"></i>
              <b-spinner v-else small variant="light" />
            </button>
          </div>
        </div>
      </div>
    </b-card>

    <b-card no-body class="mb-0">
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <div class="d-block">
              <b-icon
                style="width: 9px"
                icon="circle-fill"
                :class="data.item.status ? 'text-success' : 'text-danger'"
              />
              {{ data.item.title }}
            </div>
            <small>{{ data.item.course_sale_name }}</small>
          </b-media>
        </template>

        <template #cell(installments)="data">
          <b-badge variant="light-dark">{{
            totalInstallment(data.item.installments)
          }}</b-badge>
        </template>

        <template #cell(created_at)="{ item }">
          <span v-if="!item.updated_at">
            {{ item.created_at | datePT(true) }}
          </span>
          <span v-else>
            {{ item.updated_at | datePT(true) }}
          </span>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex-between">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              v-if="$can('Curso - Excluir', 'Pedagógico')"
              @click="confirmDelete(data.item.uuid)"
            />
            <router-link v-if="$can('Curso - Editar', 'Pedagógico')" :to="{
              name: 'courses-edit',
              params: { uuid: data.item.uuid },
            }">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="currentPage"
        :perPage="perPage"
        :totalRows="totalRows"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BAvatar,
  BMedia,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";

export default {
  components: {
    BAvatar,
    BMedia,
    BFormInput,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      optionsModalities: [],
      optionsShifts: [],
      optionsDatasheets: [],
      optionsCourseSales: [],
      optionsScholarities: [],
      optionsCourseTypes: [],
      btnSearch: false,
      filters: {
        course_sale: '',
        title: "",
        course_type: '',
        status: '',
        datasheet: '',
        scholarity: '',
      },
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      tableColumns: [
        {
          key: "title",
          label: "Título",
          sortable: false,
        },
        {
          key: "type_name",
          label: "Tipo",
          sortable: false,
          class: "text-center",
        },
        {
          key: "installments",
          label: "parcelas",
          sortable: false,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          sortable: false,
          class: "text-center",
          thStyle: "width: 210px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch(
          "Datasheet/search",
          term
        );
      }
    },
    async fetchCourseSale(term) {
      this.optionsCourseSales = await this.$store.dispatch(
        "CourseSale/forSelect",
        term
      );
    },
    totalInstallment(installments) {
      return installments.length;
    },
    search() {
      this.btnSearch = true;
      this.getData();
    },

    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },
    async getData() {
      this.$store
        .dispatch("Course/fetchAll", {
          ...this.filters,
          page: this.currentPage,
          limit: this.perPage,
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar a aula caso não tenha nenhuma disciplina vinculada.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Course/fetchDelete", uuid).then(() => {
            this.getData();
            this.notifyDefault("success");
          });
        }
      });
    },
  },
  async mounted() {
    this.optionsScholarities = await this.$store.dispatch(
      "Scholarity/allToSelect"
    );

    this.optionsCourseTypes = await this.$store.dispatch(
      "Course/forSelectTypes"
    );

    this.$root.$on("paginate:update", (res) => {
      this.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
